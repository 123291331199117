<script>
import http from '@/http'
import { onMounted, onUpdated, reactive } from 'vue'
import { useRoute } from 'vue-router'
export default {
  setup() {
    let route = useRoute()
    let info = reactive({})
    let a = reactive({})
    http.post('/?cmd=com.xqxc.api.pigear.biz.platform.PfAgreementService.getByAgreementType', {
      agreementType: route.query.agreementType  || 'MERCHANT_USER_PROTOCOL'
    }).then(data => {
      if(data.result) {
        for(let item in data.result) {
          info[item] = data.result[item]
        }
      }
    })

    onUpdated(() => {
      document.title = info.agreementName
    })
    
    return {
      info
    }
  }
}
</script>
<template>
  <div class="page">
    <div class="html-ct" v-html="info.contractContent" />
  </div>
</template>
<style scoped lang="less">
  .page { 
    padding:.3rem;
    h1 {
      margin: .35rem 0 .5rem;
      text-align:center; 
      font-size:.4rem
    }
    .html-ct {
    font-size: .28rem;
    /deep/ p { line-height:1.2; margin-top:.25rem; text-indent: .56rem;}
    /deep/ strong{
      font-weight: bold;
    }
  }
  }
  /deep/ img:nth-child(3) { margin-top:-14px}
  
</style>
